import { Outlet } from '@remix-run/react'
import { atom, useAtom } from 'jotai'

import { Button } from '~/components/ui/button'
import { Input } from '~/components/ui/input'
import { Label } from '~/components/ui/label'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '~/components/ui/sheet'
import { Icon } from '../ui/icon'
import { ScrollArea } from '../ui/scroll-area'
import { Sidebar } from './sidebar'

export function Logo(props: { className?: string }) {
  return <div className="text-xl p-2 text-gray-600">LOGO</div>
}

export const sidebarOpenAtom = atom(false)

function HamburgerMenu(props: React.PropsWithChildren<{}>) {
  const [open, setOpen] = useAtom(sidebarOpenAtom)

  return (
    <Sheet modal open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>{props.children}</SheetTrigger>
      <SheetContent
        side="left"
        className=" w-[280px] p-0 data-[state=closed]:duration-100 data-[state=open]:duration-100"
      >
        <div className="h-12 border-b pt-1">
          <Logo className="ml-4 inline-flex h-10" />
        </div>
        <ScrollArea className="h-full">
          <Sidebar
          // className="hidden lg:block"
          />
        </ScrollArea>
      </SheetContent>
    </Sheet>
  )
}

export function Layout(props: React.PropsWithChildren<{}>) {
  return (
    <div className="flex min-h-screen flex-col">
      <div className="h-12 border-b pt-1 print:hidden">
        <div className="flex flex-row">
          <HamburgerMenu>
            <Button variant="ghost" className="hover:bg-transparent md:hidden">
              <Icon name="lucide:menu" className="size-9" />
            </Button>
          </HamburgerMenu>
          <Logo className="ml-0 inline-flex h-10 md:ml-4" />
        </div>
      </div>
      <div className="grid grow md:grid-cols-[var(--sidebar-width)_auto_auto_auto_auto]">
        <Sidebar className="col-span-1 hidden h-[calc(100vh-theme('spacing.12'))] overflow-auto border-r md:block print:hidden" />
        <div className="col-span-4  h-[calc(100vh-theme('spacing.12'))] w-[100vw] overflow-auto bg-slate-50 md:w-[calc(100vw-var(--sidebar-width))] print:h-auto">
          <div className="px-4 pt-4 lg:px-8">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}
